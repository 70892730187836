<template>
  <div class="practice_certificati">
    <div>
      <span>10+<strong>年</strong></span>
      <span>专注服务</span>
    </div>
    <div>
      <span>500+<strong>人</strong></span>
      <span>驻场服务支持</span>
    </div>
    <div>
      <span>98%</span>
      <span>综合好评率</span>
    </div>
    <div>
      <span>2000+</span>
      <span>忠实客户</span>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'PracticeCertification',
  setup() {
    const state = reactive({
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="less" scoped>
.practice_certificati {
  margin-top: 103px;
  width: 100%;
  max-width: 1920px;
}

.practice_certificati {
  height: 236px;
  background: url("@/assets/Home/practice_certificati.png");
  background-size: 100% 100%;
  padding: 0 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    width: 15%;
  }
  strong {
    font-size: 14px;
  }

  span:nth-child(odd) {
    font-size: 54px;
    font-weight: 600;
    color: #ffffff;
  }

  span:nth-child(even) {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>