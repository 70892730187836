<template>
  <!-- 导航栏 -->
  <div class="navbar">
    <img class="logo" src="../assets/logoNew.png" />
    <div class="nav_btn_top" v-if="!isShowMenu">
      <img src="@/assets/Nav/menu.png" @click="changeMenu" />
    </div>
    <div :class="isShowMenu ? 'nav_box show' : 'nav_box hide'">
      <div class="nav_btn">
        <img
          v-if="isShowMenu"
          src="@/assets/Nav/close.png"
          @click="changeMenu"
        />
      </div>
      <ul class="nav">
        <li
          v-for="(item, key, index) in navList"
          :key="index"
          @click="goPath(item.path, item, index)"
        >
          <!-- @click="goPath(item.path)" -->
          <span>{{ item.name }}</span>
          <div class="interval" v-if="item.submenu && item.submenu.length">
            <!-- item.close = !item.close -->
            <span class="icon iconfont icon-chevron-up" v-if="item.close" @click="changeOpen(item, true)"
              ></span
            >
            <span class="icon iconfont icon-chevron-down" v-else @click="changeOpen(item, false)"></span>
            <ul class="subMenu" v-if="item.close">
              <!-- :style="{
              width: item.submenu.length > 5 ? '357px' : '150px',
              height: item.submenu.length > 5 ? '178px' : '150px',
              right: item.submenu.length > 5 ? '-147px' : '-40px'
            }"
            v-if="item.submenu && item.submenu.length && index === currentMenuIndex" -->
              <!-- @mouseleave="hideSubMenu" -->
              <li
                v-for="(subItem, subIndex) in item.submenu"
                :key="subIndex"
                @click.stop="goPath(subItem.path, subItem, subIndex)"
                :style="{
                  height:
                    item.submenu.length > 5
                      ? '20%'
                      : 100 / item.submenu.length + '%',
                }"
              >
                {{ subItem.name }}
              </li>
            </ul>
          </div>
        </li>
        <li>
          <img class="ewm" src="@/assets/ewm.png" />
          <!-- <img src="@/assets/icon_phone.png" />15805189384 -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Nav",
  setup() {
    const store = useStore();
    const navList = store.state.navList;
    const state = reactive({
      isShowMenu: false,
    });

    const router = useRouter();
    const goPath = (path, data, index) => {
      if (
        (path.includes("about") && path !== "/about") ||
        path === "/#provide"
      ) {
        state.isShowMenu = false;
        window.location.href = path;
      } else if (path.includes("product") && path !== "/product") {
        let address = data.link;
        // switch (path) {
        //   case '/product#Files':
        //     address = 'http://DigitalRecord.TX985.cn'
        //     break;
        //   case '/product#ERP':
        //     address = 'http://OdooSupport.TX985.cn'
        //     break;
        //   case '/product#RPA':
        //     address = 'http://RPASupport.TX985.cn'
        //     break;
        //   case '/product#Finance':
        //     address = 'http://CanteenFinancial.TX985.cn'
        //     break;
        //   default:
        //     break;
        // }
        state.isShowMenu = false;
        // for (const item of navList) {
        //   item.close = false;
        // }
        window.open(address, "_blank");
        // window.location.href = address;
      } else if (data.submenu && data.submenu.length) {
      } else {
        state.isShowMenu = false;
        for (const item of navList) {
          item.close = false;
        }
        router.push(path);
      }
      // state.isShowMenu = false;
    };
    const changeMenu = () => {
      // navList.forEach(e => {
      //   e.close = false
      // });
      state.isShowMenu = !state.isShowMenu;
      for (const item of navList) {
        item.close = false;
      }
    };
    const changeOpen = (data, bool) => {
      for (const item of navList) {
        // item.close = bool;
        item.close = false;
      }
      data.close = !bool;
      // data.close = data.close ? !data.close : true
      // navList[index].close = navList[index].close ? !navList[index].close : true
    };
    onMounted(() => {});

    return {
      navList,
      ...toRefs(state),
      goPath,
      changeMenu,
      changeOpen,
    };
  },
};
</script>
<style lang="less" scoped>
@import "../assets/Icon/font/iconfont.css";
.navbar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  background: #ffffff;
  .logo {
    width: 146px;
    // height: 40px;
  }
  .nav_btn_top {
    position: absolute;
    top: 0;
    right: 20px;
    height: 80px;
    line-height: 80px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .nav_box {
    position: fixed;
    top: 0;
    // right: 0;
    width: 200px;
    height: 100vh;
    background: #fff;
    box-shadow: 0 0 10px #ccc;
    transition: all 0.5s;
    .nav_btn {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .nav {
      li {
        margin: 10px 0;
        padding: 0 30px;
        font-size: 15px;
        font-weight: bold;
        position: relative;

        img {
          width: 20px;
          height: 20px;
        }

        .interval {
          // display: none;
          .subMenu {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
              text-align: left;
              font-weight: 400;
              width: 90%;
              padding: 5px 0;
              margin: 0;
            }
          }
          span {
            position: absolute;
            right: 0;
            font-size: 12px;
            top: 0;
            padding: 0 10px;
            width: 100%;
            text-align: right;
          }
        }
      }
    }
  }
  .show {
    right: 0;
  }
  .hide {
    right: -220px;
  }
}
.ewm {
  width: 89px !important;
  height: auto !important;
}

</style>
