import { createStore } from "vuex";

export default createStore({
  state: {
    navList: [
      { name: "首页", path: "/" },
      { name: "服务", path: "/#provide" },
      {
        name: "解决方案",
        path: "/solutions",
        submenu: [
          { name: "生物制药领域MES", path: "/solutions/pharmaceuticals" },
          { name: "数据中心", path: "/solutions/dataCenter" },
          { name: "物业管理APP", path: "/solutions/PropertyMag" },
          { name: "电商平台", path: "/solutions/eco_platform" },
          { name: "汽车导航", path: "/solutions/carNavigation" },
          { name: "外卖骑手小程序", path: "/solutions/TakeOutRider" },
          { name: "Erp项目", path: "/solutions/erp" },
          // { name: "快信通项目", path: "/solutions/fastComProject" },
          // { name: "服务管理系统", path: "/solutions/serviceManSystem" },
          // { name: "用户管理系统", path: "/solutions/userManSystem" },
        ],
      },
      // { name: "IT人力外包", path: "/ITManpowerOutsourcing" },
      {
        name: "关于锐比特",
        path: "/about",
        submenu: [
          // { name: "企业概况", path: "/about#Survey",label:'Survey' },
          // { name: "核心价值", path: "/about#CoreValue",label:'CoreValue' },
          // { name: "企业风采", path: "/about#Style",label:'Style' },
          // { name: "企业荣誉", path: "/about#Honor",label:'Honor' },
          { name: "企业概况", path: "/about?name=Survey",label:'Survey' },
          { name: "核心价值", path: "/about?name=CoreValue",label:'CoreValue' },
          { name: "企业风采", path: "/about?name=Style",label:'Style' },
          { name: "企业荣誉", path: "/about?name=Honor",label:'Honor' },
        ],
      },
      {
        name: "公司产品",
        path: "/product",
        submenu: [
          {
            name: "电子档案管理",
            link: "http://digitalrecord.tx985.cn",
            path: "/productFiles",label:'' 
          },
          {
            name: "ERP实施",
            link: "http://OdooSupport.TX985.cn",
            path: "/productERP",label:'' 
          },
          {
            name: "RPA流程自动化",
            link: "http://RPASupport.TX985.cn",
            path: "/productRPA",label:'' 
          },
          {
            name: "食堂财务管理",
            link: "http://CanteenFinancial.TX985.cn",
            path: "/productFinance",label:'' 
          },
          // { name: "电子档案管理", path: "",label:'' },
          // { name: "核心价值", path: "/about#CoreValue",label:'' },
          // { name: "企业风采", path: "/about#Style",label:'' },
          // { name: "企业荣誉", path: "/about#Honor",label:'' },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
