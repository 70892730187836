<template>
  <div id="provide" class="container">
    <div class="title">
      <div class="font_en">WHAT CAN WE DO?</div>
      <div class="font_zh">我们能为您提供什么？</div>
    </div>
    <a-carousel>
      <ul class="list" v-for="(item, index) in list" :key="index">
        <li
          v-for="(child, i) in item"
          :key="i"
          @click="toProvideDetail(child.id)"
        >
          <div>
            <div class="img-box">
              <img :src="child.bgUrl" alt="" />
            </div>
            <div class="item-title">{{ child.title }}</div>
          </div>
        </li>
      </ul>
    </a-carousel>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { CaretUpFilled } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

export default {
  name: "Provide",
  components: {
    CaretUpFilled,
  },
  setup() {
    const state = reactive({
      list: [
        [
          {
            id: 1,
            title: "电商平台开发",
            bgUrl: require("@/assets/Home/service1_1.png"),
            content:
              "锐比特凭借多年为各企业提供完整的互联网解决方案，帮助企业提升核心竞争力，精简流程，提高效率，并以安全的方式进行各项商务流程电子化、数字化处理，在电子商务解决方案方面积累了大量的经验。本公司开发过的电商系统，模式有，商家自营，商家自营加供应商入驻等电商运营模式，有VR全景商城以及3D展示。系统支持佣金结算、团购、限时抢购、实时物流、售后服务、退款原路退回等基础功能；为电商运营商提供最优质的系统和服务。",
          },
          // {
          //   id: 2,
          //   title: "人才外包",
          //   bgUrl: require("@/assets/Home/service2_1.png"),
          //   content:
          //     "本公司提供IT人员派遣业务已有7年的历史，派遣人员覆盖全国的任何一个角落，如您缺少IT技术人手，我们可以专业提供。<br />我们提供的外派人员<br />· 初级开发工程师（主要有JAVA，NET，C#，COBOL，开发经验2年以下）；<br />· 中级开发工程师（主要有JAVA，NET，C#，COBOL，开发经验3-5年）；<br />· 高级开发工程师（主要有JAVA，NET，C#，COBOL，开发经验5年以上）；<br />· 测试工程师QA；<br />· 美工及多媒体制作（2年以上相关经验）；<br />· 项目经理、系统分析/架构设计师；<br />· 咨询顾问/业务专家 ；<br />· 本地化翻译、数据录入、客服人员。",
          // },
          {
            id: 3,
            title: "企业微信开发",
            bgUrl: require("@/assets/Home/service3_1.png"),
            content:
              "微信开发即微信公众平台,企业微信平台上进行定制开发，将企业运用，各类系统搬到微信公众平台，企业微信平台上应用。请直达微信平台 http://weixin.dingruiit.com",
          },
          {
            id: 4,
            title: "企业软件定制",
            bgUrl: require("@/assets/Home/service4_1.png"),
            content:
              "锐比特依托多年在国际高端IT服务系统实施中积累的需求挖掘、系统分析、设计、开发、实施、运维的经验；秉承“我们帮您实现”的服务方针，根据国际环境的发展变化，不断引进先进的管理理念，以个性化的系统解决方案，为快速成长型企业管理的无止境改善提供智力帮助，共同应对挑战、共同增加价值、共同成长发展。",
          },
        ],
        [
          // {
          //   id: 4,
          //   title: "企业软件定制",
          //   bgUrl: require("@/assets/Home/service4_1.png"),
          //   content:
          //     "鼎锐软件依托多年在国际高端IT服务系统实施中积累的需求挖掘、系统分析、设计、开发、实施、运维的经验；秉承“我们帮您实现”的服务方针，根据国际环境的发展变化，不断引进先进的管理理念，以个性化的系统解决方案，为快速成长型企业管理的无止境改善提供智力帮助，共同应对挑战、共同增加价值、共同成长发展。",
          // },

          {
            id: 5,
            title: "RAP机器人",
            bgUrl: require("@/assets/Home/service5_1.png"),
            content:
              "鼎锐软件从2017年就开始着手RPA机器人服务，目前为止服务的客户超过70家，他们来自不同的行业，包括银行，物流，证券，制造等等。我们紧随时代潮流，将最新的设计理念和技术应用到项目中。<br />RPA概念定义：<br />RPA（Robotic ProcessAutomation）：机器人自动化，作为人工的一种补充，协助业务的完成，又被叫做虚拟的劳动者（DigitalLabor），是认知技术（规则引擎・机会学习・人工智能等）的活用，通过规则引擎和画面识别技术，将有规则的作业自动化，从而使作业更高效！<br />优点：<br />压倒性优势的性能表现：依据指定的规则进行作业，没有错误！1小时的作业量是人工作业约180倍！<br />劳动规范上的问题改善：可以365天x24小时连续工作不需要休息，更不需要加班工资，也不用担心离职的问题！<br />高度的柔性：柔性的客户化，不需要编程就可以导入，当业务流程发生变化时，只需要重新变更流程即可。<br />",
          },
          {
            id: 6,
            title: "移动APP开发",
            bgUrl: require("@/assets/Home/service6_1.png"),
            content:
              "针对安卓，苹果手机，平板类，大屏类等进行各类系统软件的开发。",
          },
          {
            id: 7,
            title: "物联网开发",
            bgUrl: require("@/assets/Home/service7_1.png"),
            content:
              "通过主流的物联网协议通讯，在智能设备与云端之间建立安全的双向连接，快速实现各类物联网项目。同时可以提供各类物联网解决方案",
          },
        ],
        [
          // {
          //   id: 7,
          //   title: "物联网开发",
          //   bgUrl: require("@/assets/Home/service7_1.png"),
          //   content:
          //     "通过主流的物联网协议通讯，在智能设备与云端之间建立安全的双向连接，快速实现各类物联网项目。同时可以提供各类物联网解决方案",
          // },
          {
            id: 8,
            title: "大数据分析",
            bgUrl: require("@/assets/Home/service8_1.png"),
            content:
              " 为各种大中小型公司提供信息系统后期产品维护与升级服务、服务器管理及调优、网络与局域网的规划维护和组建、网络综合布线工程、企业监控网络建设、电脑及外设维修维护、网络技术支持及咨询，并提供7*24的全天候技术服务，费用透明公开，真正为企业做好信息化建设。",
          },
          // {
          //   id: 9,
          //   title: "对日软件外包",
          //   bgUrl: require("@/assets/Home/service9_1.png"),
          //   content:
          //     "鼎锐软件是一家专业的对日软件外包(离岸开发)服务公司，目前主要承接对日JAVA、.NET（C#，VB.NET）、COBOL、PHP、 H5、C++、IOS、Android等语言类型的项目。目前在金融，物流，证券，电商，汽车等行业都取得了骄人的业绩。目前寻求国内，日本的合作伙伴进行更深入的合作。<br />地址： <br />苏州鼎锐软件科技有限公司 <br />地址：苏州工业园区金鸡湖大道1355号&nbsp;国际科技园一期204A-1 <br />电话：0512-65984482 <br />邮件：groupzhaopin@dingruiit.com<br />泰州腾翔信息科技有限公司（苏州鼎锐分公司）<br />地址：江苏省泰州市海陵工业园区青年北路26号科技大厦6楼<br />电话：0523-86083978 <br />邮件：groupzhaopin@dingruiit.com<br />",
          // },
        ],
      ],
    });
    const router = useRouter();
    const toProvideDetail = (id) => {
      router.push({
        path: "/provideDetail",
        query: {
          id: id,
        },
      });
    };
    return {
      ...toRefs(state),
      toProvideDetail,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  margin: 30px auto;
}
.title {
  text-align: center;
  .font_en {
    font-size: 16px;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 16px;
  }
  .font_zh {
    font-size: 21px;
    font-weight: 600;
    color: #2083ee;
    line-height: 20px;
  }
}
.list {
  display: flex !important;
  padding: 20px 5px 0;
  li {
    width: calc((100vw - 40px) / 3);
    margin: 10px 5px;
    & > div {
      .img-box {
        width: calc((100vw - 40px) / 3);
        height: calc((100vw - 40px) / 3);
        border-radius: 14px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 70px;
          height: 70px;
        }
      }
      .item-title {
        color: #333;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
.ant-carousel :deep(.slick-dots) {
  bottom: 0;
}
.ant-carousel :deep(.slick-dots li button) {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d8d8d8;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: #2083ee;
}
</style>
