<template>
  <div class="foot">
    <div class="container">
      <div class="top">
        <div class="contact">
          <div>联系我们</div>
          <div>
            <p>电话：17366280613</p>
            <!-- <p>邮箱：zhangzhu@dingruiit.com</p> -->
            <p>地址：泰州市海陵区城中青年路26号</p>
          </div>
        </div>
        <div id="map"></div>
      </div>
    </div>
    <div class="bottom">
      <div>
        锐比特智能科技信息技术有限公司版权所有<br />
        苏ICP备19074100号-4 Copyright@2016
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import AMapLoader from "@amap/amap-jsapi-loader";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "Foot",
  setup() {
    const store = useStore();
    const info = reactive({
      service1: [
        "电商平台开发",
        // "人才外包",
        "企业软件定制",
        "企业微信定制",
        "RAP机器人",
        "手机端开发",
      ],
      service2: [
      // "手机端开发",
      "物联网开发", "大数据分析"
       // "对日软件外包"
    ],
      solutions1: [
        { name: "生物制药领域MES", path: "/solutions/pharmaceuticals" },
        { name: "数据中心", path: "/solutions/dataCenter" },
        { name: "物业管理APP", path: "/solutions/PropertyMag" },
        { name: "电商平台", path: "/solutions/eco_platform" },
        { name: "汽车导航", path: "/solutions/carNavigation" },
      ],
      solutions2: [
        { name: "外卖骑手小程序", path: "/solutions/TakeOutRider" },
        { name: "Erp项目", path: "/solutions/erp" },
        // { name: "快信通项目", path: "/solutions/fastComProject" },
        // { name: "服务管理系统", path: "/solutions/serviceManSystem" },
        // { name: "用户管理系统", path: "/solutions/userManSystem" },
      ],
      about: computed(() => store.state.navList[3].submenu),
    });

    const router = useRouter();

    const goSolution = (path) => {
      router.push(path);
      window.scrollTo(0, 460);
    };

    const goAbout = (path) => {
      window.location.href = path;
    };
    const service = (path) => {
      window.location.href = path;
    };
    const initMap = () => {
      AMapLoader.load({
        key: "4d3027dc95493bd92bcae1a48ccc0bac",
        version: "2.0",
        plugins: [
          "AMap.Scale", //工具条，控制地图的缩放、平移等
          "AMap.ToolBar", //比例尺，显示当前地图中心的比例尺
        ],
      })
        .then((AMap) => {
          let map = new AMap.Map("map", {
            //设置地图容器id
            zoom: 16, //初始化地图层级
            viewMode: "2D", //是否为3D地图模式
            center: [118.880055, 32.092134], //初始化地图中心点位置
          });
          map.addControl(new AMap.Scale());
          map.addControl(new AMap.ToolBar());
          map.add(
            new AMap.Marker({
              icon: require("@/assets/marker.png"),
              position: map.getCenter(),
              offset: new AMap.Pixel(-10, -15),
            })
          );
          let infoWindow = new AMap.InfoWindow({
            //创建信息窗体
            isCustom: true, //使用自定义窗体
            anchor: "bottom-center", //信息窗体的三角所在位置
            // content: `<a href="https://uri.amap.com/marker?position=118.880055,32.092134&name=锐比特智能科技有限公司" target="_blank" style="display:block;width:170px;text-align:center;line-height:25px;font-size:12px;color:#fff;background-color:#409eff;border-radius:5px;box-shadow:0 0 5px #409eff;user-select:none;cursor:pointer;">锐比特智能科技有限公司<a/>`, //信息窗体的内容可以是任意html片段
            content: `<a href="https://uri.amap.com/marker?position=119.891175,32.489076&name=锐比特智能科技有限公司" target="_blank" style="display:block;width:170px;text-align:center;line-height:25px;font-size:12px;color:#fff;background-color:#409eff;border-radius:5px;box-shadow:0 0 5px #409eff;user-select:none;cursor:pointer;">锐比特智能科技有限公司<a/>`, //信息窗体的内容可以是任意html片段
            offset: new AMap.Pixel(0, -15),
          });
          infoWindow.open(map, [119.891175, 32.489076]); //填写想要窗体信息指示的坐标
        })
        .catch((e) => {
          console.log(e);
        });
    };
    onMounted(() => {
      initMap();
    });
    return {
      ...toRefs(info),
      goSolution,
      goAbout,
      service,
      initMap,
    };
  },
};
</script>
<style lang="less" scoped>
.foot {
  width: 100%;
  color: #333;
  position: relative;
  .container {
    margin: 0px auto;
    padding: 0 20px;

    .top {
      display: flex;
      flex-direction: column;
      .contact {
        padding-top: 20px;
        & > div:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
          line-height: 33px;
          padding: 9px 0;
          border-bottom: 1px solid #c6c6c6;
        }
        & > div:nth-child(2) {
          margin-top: 17px;
          font-size: 13px;
          line-height: 24px;
        }
      }
      #map {
        width: 100%;
        height: 230px;
        :deep(.amap-logo) {
          display: none !important;
        }
        :deep(.amap-copyright) {
          bottom: -100px;
          display: none !important;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    max-width: 1920px;
    background: #f5f5f5;
    font-size: 12px;
    color: #666;
    line-height: 20px;
    text-align: center;
    padding: 10px 16px;
    margin-top: 20px;
  }
}
</style>
