<template>
  <div class="about_container">
    <div class="title">
      <div class="font_en">ABOUT US</div>
      <div class="font_zh">关于我们</div>
    </div>
    <div class="content">
      <!-- <p>
        锐比特智能科技有限公司于2015年成立于中国南京市玄武区，是一家针对企事业单位信息化平台建设方案的解决商。自成立以来，公司站在技术市场的最前端，在各项高新技术层面均积累了丰富的经验。作为企业两化融合解决方案的提供商，其在电子商务平台的搭建、企业ERP平台搭建、企事业单位OA平台搭建、企事业个性化软件定制、App、小程序软件的定制等方面也颇具实力，其独特且严谨的开发流程，获得了客户高度的认可和无数好评。
        核心竞争力：依托公司优秀的开发管理人才，为客户提供系统的定制、开发以及解决方案的制定、技术与人才外包等方面的专业、持久的个性化服务。
        案例：RPA、SEO引擎优化、小程序开发、移动APP的开发、ERP系统定制化开发、OA办公系统定制化、电子商务平台定制化、即时通讯系统、政府公众平台的定制开发等等。
      </p> -->
      <p>
        泰州锐比特智能科技有限公司于2019年04月10日成立。公司致力于自动化和智能化解决方案，主营：智能技术研发；软件开发；计算机网络技术开发；公司可为客户定制化提供SCADA（数据采集）+IOT（物联网）+MES（制造执行系统）解决方案
作为工业物联网产品与解决方案供应商， 专注服务工业领域企业类用户。 提供从数据采集、互联互通、边缘计算到系统开发等全套技术解决方案，围绕行业发展需求，为客户创造价值。拥有优异的研发团队和严格的研发管理体系，配套完善的供应链生态系统， 为客户提供更快、更稳定、更优的产品。
      </p>
      <img src="@/assets/Home/home_about.png" alt="" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";

export default {
  name: "PracticeCertification",
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.about_container {
  width: 100%;
  margin-top: 50px;

  .title {
    text-align: center;
    .font_en {
      font-size: 16px;
      font-weight: 600;
      color: #e9e9e9;
      line-height: 16px;
    }
    .font_zh {
      font-size: 21px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
    }
  }
  .content {
    padding: 10px;
    margin-top: 20px;
    p {
      width: 100%;
      font-size: 13px;
      color: #404040;
      margin-bottom: 10px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
