<template>
  <!-- 导航栏 -->
  <div class="navbar">
    <img class="logo" src="../assets/logoNew.png" />
    <ul class="nav">
      <li v-for="(item, index) in navList" :key="index" @mouseenter="showSubMenu(index)" @mouseleave="hideSubMenu" @click="goPath(item.path)">
        {{ item.name }}
        <div class="interval">
          <ul
            class="subMenu"
            :style="{
              width: item.submenu.length > 5 ? '357px' : '150px',
              height: item.submenu.length > 5 ? '178px' : '150px',
              right: item.submenu.length > 5 ? '-147px' : '-40px'
            }"
            v-if="item.submenu && item.submenu.length && index === currentMenuIndex"
          >
            <!-- @mouseleave="hideSubMenu" -->
            <li
              v-for="(subItem, subIndex) in item.submenu"
              :key="subIndex"
              @click.stop="goPath(subItem.path,subItem)"
              :style="{ height: item.submenu.length > 5 ? '20%' : 100 / item.submenu.length + '%' }"
            >
              {{ subItem.name }}
            </li>
          </ul>
        </div>
      </li>
      <li class="ewmLi">
        <!-- <img src="@/assets/icon_phone.png" />15805189384 -->
        <img src="@/assets/icon_phone.png" />
        <span>联系我们</span>
        <div class="ewm">
          <div class="sj"></div>
          <img src="@/assets/ewm.png" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed, reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'Nav',
  setup() {
    const store = useStore()
    const navList = computed(() => store.state.navList)
    const info = reactive({
      currentMenuIndex: -1
    })

    const showSubMenu = (index) => {
      info.currentMenuIndex = index
    }

    const hideSubMenu = () => {
      info.currentMenuIndex = -1
    }

    const router = useRouter()
    const goPath = (path,data) => {
      if ((path.includes('about') && path !== '/about') || path === '/#provide') {
        // window.location.href = path
        router.push(path)
      } else if (path.includes("product") && path !== '/product') {
        let address = data.link
        window.open(address, '_blank')
      } else if(path === '/product'){

      } else {
        router.push(path)
      }
      info.currentMenuIndex = -1
    }

    onMounted(() => {})

    return {
      navList,
      ...toRefs(info),
      showSubMenu,
      hideSubMenu,
      goPath
    }
  }
}
</script>
<style lang="less" scoped>
.logo {
  width: 146px;
  // height: 40px;
  margin-left: 315px;
}
.navbar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  background: #ffffff;
  .nav {
    margin: 0;
    margin-left: 300px;
    height: 80px;
  }
  .nav > li {
    padding: 0;
    margin-right: 97px;
    float: left;
    display: block;
    color: #333;
    font-size: 16px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      color: #ef0400;
    }
    .interval {
      width: 100%;
      position: absolute;
      bottom: -5px;
      height: 5px;
      .subMenu {
        position: absolute;
        top: 5px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        box-sizing: content-box;
        background: #fff;
        border-radius: 8px;
        padding: 15px 0;

        li {
          width: 40%;
          // width: 100%;
          margin: 0 auto;
          text-align: left;
          color: #333333;
          font-size: 14px;
          // text-align: left;
          font-weight: 500;
          display: flex;
          align-items: center;

          // &:nth-child(1),
          // &:nth-child(2),
          // &:nth-child(3),
          // &:nth-child(4),
          // &:nth-child(5) {
          //   margin-left: 47px;
          // }
        }
        li:hover {
          color: #ef0400;
        }
      }
    }
  }
  .nav > li:nth-child(5) .subMenu{
    li{
      width: 65%;
    }
  }
  .nav > li:hover {
    cursor: pointer;
  }
  .nav > li:last-child {
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 1800px) {
  .logo {
    margin-left: 10%;
  }
  .navbar {
    .nav {
      width: 65%;
      margin-left: 15%;

      & > li {
        margin-right: 7%;
      }
    }
  }
}
.ewm{
  position: absolute;
  display: none;
  bottom: -130px;
  background: #fff;

  img{
    width: 95px!important;
    height: auto!important;
    margin: 10px!important;
  }
  .sj{
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: none;
    border-bottom-color: #fff;
    position: absolute;
    top: -8px;
    left: calc(50% - 8px);
  }
}
.ewmLi:hover{
  .ewm{
    display: block;
  }
}
</style>
