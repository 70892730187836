<template>
  <div class="home_Practice">
    <div class="title">
      <div class="font_en">COOPERATIVE PARTNER</div>
      <div class="font_zh">优质合作客户</div>
    </div>
  </div>
  <a-carousel class="parent" dotsClass="dots">
    <div>
      <img src="@/assets/Home/parent1.png" />
    </div>
    <div>
      <img src="@/assets/Home/parent2.png" />
    </div>
    <div>
      <img src="@/assets/Home/parent3.png" />
    </div>
  </a-carousel>
</template>
<script>
import { reactive, toRefs } from "vue";

export default {
  name: "PracticeCertification",
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="less" scoped>
.home_Practice {
  padding-top: 30px;
}
.title {
  text-align: center;
  .font_en {
    font-size: 16px;
    font-weight: 600;
    color: #e9e9e9;
    line-height: 16px;
  }
  .font_zh {
    font-size: 21px;
    font-weight: 600;
    color: #333;
    line-height: 20px;
  }
}
.parent {
  margin-top: 30px;
  padding: 10px;
}
img {
  width: 100%;
  height: auto;
}
.ant-carousel :deep(.dots) {
  bottom: -36px;
}
.ant-carousel :deep(.slick-dots li button) {
  background: #d8d8d8;
  width: 45px;
  height: 5px;
}
.ant-carousel :deep(.slick-dots li.slick-active button) {
  background: #2083ee;
}
</style>
